import request from '@/utils/request'

// 查询小程序文章类型管理列表
export function listWxappType(query) {
  return request({
    url: '/work/WxappType/list',
    method: 'get',
    params: query
  })
}

// 查询小程序文章类型管理详细
export function getWxappType(id) {
  return request({
    url: '/work/WxappType/' + id,
    method: 'get'
  })
}

// 新增小程序文章类型管理
export function addWxappType(data) {
  return request({
    url: '/work/WxappType',
    method: 'post',
    data: data
  })
}

// 修改小程序文章类型管理
export function updateWxappType(data) {
  return request({
    url: '/work/WxappType/update',
    method: 'post',
    data: data
  })
}

// 删除小程序文章类型管理
export function delWxappType(id) {
  return request({
    url: '/work/WxappType/remove/' + id,
    method: 'get'
  })
}

// 导出小程序文章类型管理
export function exportWxappType(query) {
  return request({
    url: '/work/WxappType/export',
    method: 'get',
    params: query
  })
}